import React from "react"
import { useStaticQuery, graphql } from 'gatsby';
import { FaqDetail } from "../../../components/faq/detail"

const breadcrumbsData: { text: string; path: string }[] = [
  { text: `FAQ`, path: `question` },
  { text: `お仕事のご依頼やご相談について`, path: `question/business` },
]

const faqNavItems: { text: string; path: string }[] = [
  { text: `お仕事のご依頼やご相談について`, path: `#faq_business` },
]

const FaqTitle: string = "お仕事のご依頼やご相談に関するよくある質問";


export default function FaqBusinessPage() {
  const { site: { siteMetadata: { faqDetail } } } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          faqDetail {
            business {
              details {
                answer
                question
              }
              path
              text
            }
          }
        }
      }
    }
  `);

  const [ faqDetails ] = faqDetail;

  return (
    <FaqDetail
      breadcrumbsData={breadcrumbsData}
      faqNavItems={faqNavItems}
      faqDetails={faqDetails.business}
      faqTitle={FaqTitle}
    />
  )
}